// TODO add this to snap-ui
.ButtonCard {
  cursor: pointer;
  display: flex;
  width: 100%;
  max-width: 341px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid #d1d1d1;
  background-color: #ffffff;
  padding: 1rem 1.5rem;
  &:hover {
    border: 1px solid #999999;
  }
}

.ButtonCard--title {
  color: #191919;
  font-size: 17px;
  font-family: Lato;
  line-height: 24px;
}
.ButtonCard--subtitle {
  color: #666666;
  /* Mobile/02 Caption/Default */
  font-size: 13px;
  font-family: Lato;
  line-height: 16px;
  text-align: left;
}
