#bulk-update-page {
  .container {
    display: flex;
    width: 460px;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: var(--sui-colors-neutral-background-weak);
  }
  .input-file-cursor {
    cursor: 'pointer';
  }

  .error-message {
    color: var(--sui-colors-red-6);
  }
}
