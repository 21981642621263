.multiselect-picker-container {
  width: 100%;
  background-color: white;
  position: relative;

  button {
    padding: 0;
    border: none;
  }

  .multiselect-picker-selected {
    color: #383838;
    width: 100%;
    min-height: 48px;
    line-height: 30px;
    padding: 0 6px;
    box-sizing: border-box;
    box-shadow: none;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    font-family: inherit;
    transition: 0.2s;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    &[aria-disabled='true'] {
      background: #fbfbfb;
      cursor: not-allowed;
    }

    .arrow {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }

    .placeholder {
      color: #999;
    }

    .selected {
      margin: 4px 2px;
      font-size: 14px;
      height: 28px;
      padding: 0 8px;
      padding-right: 4px;
      background-color: #ebf4ff;
      line-height: 1;
      border-radius: 5px;
      display: inline-flex;
      align-items: center;
      font-weight: 600;
      letter-spacing: 0.42px;

      span {
        line-height: 1.125;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
      }

      button {
        margin: 0;
        padding: 0;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        position: relative;
        margin-left: 8px;
        top: 1px;
      }
    }
  }

  .multiselect-picker-options {
    position: absolute;
    width: 100%;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    background-color: white;
    z-index: 10;
    box-shadow: 18.6024px 47.4863px 100px rgba(0, 0, 0, 0.07), 12.0571px 30.7782px 58.5648px rgba(0, 0, 0, 0.0531481),
      7.16535px 18.291px 31.8519px rgba(0, 0, 0, 0.0425185), 3.72047px 9.49727px 16.25px rgba(0, 0, 0, 0.035),
      1.51575px 3.86926px 8.14815px rgba(0, 0, 0, 0.0274815), 0.344488px 0.879377px 3.93519px rgba(0, 0, 0, 0.0168519);

    .options {
      padding: 19px;
      max-height: 300px;
      overflow-y: auto;
    }

    .actions {
      border-top: 1px solid #d1d1d1;
      padding: 8px 19px;
      display: flex;
      justify-content: flex-end;

      button {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.42px;
      }

      .btn-clear {
        color: #191919;
      }

      .btn-save {
        margin-left: 8px;
        color: #006ff5;
      }
    }
  }
}
