#organization-form {
  display: flex;
  flex-flow: row wrap;

  .fields-group {
    width: 100%;
    justify-content: space-between;
    display: flex;
    border-radius: 8px;
    flex-flow: row wrap;
    background-color: var(--sui-colors-neutral-background-weak);
  }

  .field-50 {
    width: calc(50% - 12px);
  }
}
