.roles-divider {
  margin-top: 20px;
  border-top: 1px solid #cbd0d6;
}

.delete-button {
  align-self: end;

  span {
    display: flex;
    align-items: center;
  }
}
