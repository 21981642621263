.HockeyCanada {
    height: calc(100% - 48px);

    .hockey_canada--add-connection-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .hockey_canada--table_container {
        height: calc(100% - 144px);
        overflow-y: scroll;
    }

    .hockey_canada--status-bar {
        padding: 10px;
        background-color: #e6f9e6;
        border: 1px solid #2ecc71;
        border-radius: 5px;
        margin-bottom: 20px;
        text-align: center;

        .status-message {
            font-size: 16px;
            color: #2ecc71;

            .status-icon {
                font-size: 18px;
                margin-right: 5px;
            }
        }
    }

    .hockey_canada--form-container {
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 20px;

        .form-title {
            font-size: 20px;
            margin-bottom: 10px;
        }

        .form-description {
            font-size: 14px;
            color: #555;
            margin-bottom: 20px;
        }

        .hockey_canada--form {
            display: flex;
            flex-direction: column;
            gap: 15px;


            .error-message {
                color: #e74c3c;
                font-size: 12px;
                margin-top: 5px;
            }

            .success-message {
                color: #2ecc71;
            }

            .scope-id {
                display: flex;
                width: 80%;
                flex-direction: column;
            }

            .inputs {
                display: flex;
                gap: 20px;
            }

            .form-separator {
                margin-top: 8px;
                text-align: center;
                font-weight: bold;
                color: #555;
            }

            .form-group {
                display: flex;
                flex-direction: column;
                width: 100%;

                label {
                    font-size: 14px;
                    margin-bottom: 5px;
                    font-weight: bold;
                }

                input {
                    padding: 8px;
                    font-size: 14px;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                }
            }



            .save-button {
                align-self: flex-start;
                background-color: #2ecc71;
                color: white;
                border: none;
                padding: 10px 20px;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    background-color: #27ae60;
                }
            }
        }
    }

    .modal-actions {
        display: flex;
        justify-content: space-between;
    }
}