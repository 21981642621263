$header-height: 48px;
$max-width-large: 1440px;

header {
  justify-self: flex-start;
  border-bottom: 1px solid #cbd0d6;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.03);
  position: relative;
  height: $header-height;

  > div {
    width: 100%;
    height: 100%;
    max-width: $max-width-large;
    margin: 0 auto;
    position: relative;
  }

  div[data-testid='profile-image-text'] {
    height: 36px !important;
    width: 36px !important;
  }

  .back-button {
    min-width: auto;
    min-height: auto;
  }
}
